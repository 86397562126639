
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    ConversationHeader,
    TypingIndicator,
    VoiceCallButton,
    InfoButton,
    Avatar,
  } from "@chatscope/chat-ui-kit-react"
  import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
  import { useState, useRef, useEffect } from 'react'
  import { useChannel } from '@ably-labs/react-hooks'
  import { Types } from "ably"

  import React from "react";
  import './index.css';
 
import { v4 as uuidv4 } from 'uuid';
const channelId = uuidv4();

    const widgetContainer = document.getElementById('widget-root');
    const prospectID = widgetContainer.getAttribute('prospectId') || "customId-created-1234"; //need to remove or condition for production
    console.log(prospectID, "prospectID")

const updateChatbotMessage = (conversation, message) => {
    const interactionId = message.data.interactionId;

    const updatedConversation = conversation.reduce((acc, e) => [
        ...acc,
        e.id === interactionId
            ? { ...e, message: e.message + message.data.token }
            : e,
    ], []);

    return conversation.some((e) => e.id === interactionId)
        ? updatedConversation
        : [
            ...updatedConversation,
            {
                id: interactionId,
                message: message.data.token,
                speaker: "bot",
                date: new Date(),
                liked: false,
            },
        ];
};



function Widget() {
    // State variables
    const [text, setText] = useState("");
    const [conversation, setConversation] = useState([]);
    const [conversationId, setConversationId] = useState();
    const [botIsTyping, setBotIsTyping] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [ conversationStatus, setConversationSatus] = useState("askQuestions");
    let typingTimeout = null; // Outside your useChannel hook
    const [answers, setAnswers] = useState({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);


    let questions = ["What is your name?",
                    "What is your email?",
                    "Are you looking for individual or couples therapy?",
                    "Do you plan to use insurance?",
                    "If you plan to use Insurace, do you have out of network plan?",
                    "Do you want to meet in person or virtually?",
                    "What kind of a therapist were you open to working with? Any specific age range, race, or gender?",
                    "Are you here to work with someone specifically from our practice?",
                    "How did you hear about us? Did someone refer you? If so, who?",
                    `Great! Please book a time with Claudio. <br /><a 
                        href="https://usemotion.com/meet/thomas-melching/meeting?d=25" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style="display:block; padding: 12px; background-color: #0366d6; color: #fff; text-decoration: none; border-radius: 40px; font-weight: 600; transition: background-color 0.2s; text-align: center; line-height: 1.5;"
                        onMouseOver="this.style.backgroundColor='#024a8b'"
                        onMouseOut="this.style.backgroundColor='#0366d6'"
                    >Book your meeting here</a>`
                ]

    const inputRef = useRef(null);
    const lastMessageRef = useRef(null);


    useChannel( channelId, (message) => {
        switch(message.data.event) {
            case "response": 
                setConversation((state) => updateChatbotMessage(state, message))
               

                if (inputRef.current) {
                    inputRef.current.focus();
                }
                // If there's a previous timer, clear it to prevent multiple timers
                if(typingTimeout) {
                    clearTimeout(typingTimeout);
                }
                // Start a new timer
                typingTimeout = setTimeout(() => {
                    setBotIsTyping(false);
                }, 5000); // 5 seconds
                break;
            case "status":
                setBotIsTyping(message.data.message)
                break;
            case "responseEnd":
            default:
                setBotIsTyping(false);
                setTimeout(() => {
                    if (lastMessageRef.current) {
                        lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 222);
                // Clear the timer when you receive the responseEnd event
                if(typingTimeout) {
                    clearTimeout(typingTimeout);
                }
                break;
        }
    });

    useEffect(() => {
        let botMessage = "";
    
        switch (conversationStatus) {
            case "Book":
                botMessage =  `Great! Please book a time with Claudio. <br /><a 
                href="https://usemotion.com/meet/thomas-melching/meeting?d=25" 
                target="_blank" 
                rel="noopener noreferrer" 
                style="display:block; padding: 12px; background-color: #0366d6; color: #fff; text-decoration: none; border-radius: 4px; font-weight: 600; transition: background-color 0.2s; text-align: center; line-height: 1.5;"
                onMouseOver="this.style.backgroundColor='#024a8b'"
                onMouseOut="this.style.backgroundColor='#0366d6'"
              >Book your meeting here</a>`;
                break;
            case "Stuck":
                botMessage =`It's completely okay if you're not ready to open up further here. A conversation with Claudio is probably best. If you feel it's the right step, I'd recommend booking a session with Claudio to discuss your experience
                <br />
                <a 
                href="https://usemotion.com/meet/thomas-melching/meeting?d=25" 
                target="_blank" 
                rel="noopener noreferrer" 
                style="display:block; padding: 12px; background-color: #0366d6; color: #fff; text-decoration: none; border-radius: 4px; font-weight: 600; transition: background-color 0.2s; text-align: center; line-height: 1.5;"
                onMouseOver="this.style.backgroundColor='#024a8b'"
                onMouseOut="this.style.backgroundColor='#0366d6'"
              >Book your meeting here</a>`;
                break;
            case "Alert":
                botMessage =`Your well-being is important to me, and I want to make sure you're okay.<br> If you are in a life-threatening situation, please call 911 or get immediate help through one of these resources: https://www.samhsa.gov/find-help`;
                break;
            default:
                return; // If the status doesn't match any expected values, do nothing.
        }
    
        // Add the message to the conversation
        const botResponse = {
            message: botMessage,
            speaker: "bot",
            date: new Date()
        };
    
        setConversation((state) => [...state, botResponse]);
        setTimeout(() => {
            if (lastMessageRef.current) {
                lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 222);
    }, [conversationStatus]); // Only re-run the effect when the conversationStatus changes
    

    const sendUserInput = async (input) => {
        // TODO: Add logic for sending user input
        let history = conversation
        .filter(entry => !entry.isQA)
        .map(data => data.message)
        .reverse()
        .join("\n");

        console.log("conver",conversationStatus)
        try {
            setBotIsTyping(true);
          const response = await fetch("https://fitmentalhelp.com/chat/prospectiveUserChat", { //"https://fitmentalhelp.com/chat" "http://127.0.0.1:3000/chat"
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ conversationId: conversationId, initialStatus: conversationStatus, history, query: input, userId:channelId}), // Include the selected topic in the user input
          });

          const responseData = await response.json();
          const newStatus = responseData.status; // Assuming the API returns the new status
          setConversationSatus(newStatus); // Update the conversation status
        } catch (error) {
          console.error("Error submitting message:", error);
        } finally {
          setBotIsTyping(false);
        }
    };

    const submit = async () => {
        // TODO: Add logic for submitting a message

        setConversation((state) => [
            ... state, {
            message: text,
            speaker: "user",
            date: new Date(),
            isQA: true 
            }
        ])
        if (currentQuestionIndex < questions.length) {
            // Store the user's answer
            let updatedAnswers = {
                ...answers,
                [questions[currentQuestionIndex]]: text
            };
            setAnswers(updatedAnswers);
    
            // If there are more questions, ask the next one
            if (currentQuestionIndex < questions.length - 1 && conversationStatus === "askQuestions") {
                setCurrentQuestionIndex(prevIndex => prevIndex + 1);
                addBotMessage(questions[currentQuestionIndex + 1]);
                if (lastMessageRef.current) {
                    lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            } else if (currentQuestionIndex === questions.length - 1 && conversationStatus === "askQuestions") {
                // If it's the last question, change the status to "UnderstandIssue"
                setConversationSatus("UnderstandIssue");
                let updatedAnswers = {
                    ...answers,
                    [questions[currentQuestionIndex]]: text
                };
                setAnswers(updatedAnswers);
                try {
                    setBotIsTyping(true);
                    const response = await fetch("https://fitmentalhelp.com/chat/startProspectiveUserChat", { //"https://fitmentalhelp.com/chat" "http://127.0.0.1:3000/chat"
                        method: "POST",
                        headers: {
                        "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ data: answers, awsId: prospectID}), // Include the selected topic in the user input
                    });

                    const responseData = await response.json();
                    const conversationId = responseData.conversationId; // Assuming the API returns the new status
                    console.log(responseData, "responseData from startProspectiveUserChat")
                    setConversationId(conversationId);
                } catch (error) {
                console.error("Error submitting message:", error);
                } finally {
                setBotIsTyping(false);
                }

                // TODO save the data and start converstaion
                addBotMessage("Great. Now that those are out of the way, can you share more about why you’re interested in working with Claudio?")
            }
        }   

        if(conversationStatus !== "askQuestions"){
            // Send the user input to the API
            sendUserInput(text);    
        }
        setText("");
    };

    const addBotMessage = (message) => {
        setConversation(prev => [
            ...prev,
            {
                message: message,
                speaker: "bot",
                date: new Date(),
                isQA: true // mark this as a Q&A message
            }
        ]);
        setTimeout(() => {
            if (lastMessageRef.current) {
                lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 222);
    };

    const emilyIco = "https://trytherapyaiwidget.s3.us-west-1.amazonaws.com/build/AI_therapist_female_headshot.png"

    const handleBackClick = () => {
        // Clear the conversation
        setConversation([]);
    
        // Reset the topic selection
        setSelectedTopic(null);
    
        // Indicate the bot isn't typing
        setBotIsTyping(false);
    
        // Reset the conversation status to its initial value
        setConversationSatus("rootCauseIdentification");
        setAnswers({});
        setCurrentQuestionIndex(0);
        setConversationSatus("askQuestions");
    };
    
    
    const startChatWithAssistant = () => {
        setSelectedTopic("Help me feel less anxious");
        const mockedBotResponse = {
            message: "Hi! Nice to meet you. I’m Claudio’s AI assistant designed to learn more about you before scheduling your first session. Let’s get started.",
            speaker: "bot",
            date: new Date(),
            showBookingLink: false,
            isQA: true // mark this as a Q&A message
        };

        setConversation((state) => [...state, mockedBotResponse]);

        addBotMessage(questions[currentQuestionIndex]);
    };

    const handleInfoButtonClick = () => {
        window.open("https://tryTherapy.ai", "_blank");
    };

    return (
        <div 
        className="widget-container" 
        style={selectedTopic !== null ? { maxHeight: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' } : {}}
    >
        {!selectedTopic ? (
            <div style={{ flex: 1 }}>
                <button className="widget-button widget-button-chat" onClick={startChatWithAssistant}>Book a session</button>
            </div>
        ) : (
            <MainContainer style={{ flex: 1 }}>
                    <ChatContainer>
                    <ConversationHeader>
                        <ConversationHeader.Back onClick={handleBackClick} />
                        <Avatar onClick={handleInfoButtonClick}  src={emilyIco} name="Emily" />
                        <ConversationHeader.Content onClick={handleInfoButtonClick}  userName="TherapyAI" />                       
                        <ConversationHeader.Actions>                                                                             
                        {/* <StarButton title="Add to favourites" /> */}
                        {/* <VoiceCallButton title="Start voice call" /> */}
                        {/* <VideoCallButton title="Start video call" /> */}
                        <InfoButton title="Show info" onClick={handleInfoButtonClick} />
                        </ConversationHeader.Actions>
                    
                    </ConversationHeader>

                    <MessageList scrollBehavior="smooth" typingIndicator={botIsTyping && <TypingIndicator content="Therapy AI is typing" />}>
                    {conversation.length !== 0 && conversation.map((entry, index) => (
                        <React.Fragment key={index}>
                            <div ref={index === conversation.length - 1 ? lastMessageRef : null}>
                            <Message
                                model={{
                                    type: "custom",
                                    sender: entry.speaker,
                                    position: "single",
                                    direction: entry.speaker === "bot" ? "incoming" : "outgoing"
                                }}
                            >
                                <Message.CustomContent className="custom-content-green">
                                    <span dangerouslySetInnerHTML={{__html: entry.message}} />
                                </Message.CustomContent>
                                {/* <Message.Footer
                                        // sentTime={timeago.format(entry.date)}
                                        sentTime={entry.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        sender={entry.speaker === 'bot' ? "Therapy AI" : "You"}
                                    /> */}
                                     {/* {entry.speaker === 'bot' ? 
                                (<Message.Footer sentTime={entry.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} sender={entry.speaker =="bot"? "Therapy AI" : "You" } />) : 
                                (<Message.Footer sentTime={entry.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} sender={entry.speaker =="bot"? "Therapy AI" : "You"} />)
                            } */}
                            </Message>
                           
                            </div>
                        </React.Fragment>
                    ))}
    
                     
                    </MessageList>

                    <MessageInput
                        ref={inputRef}
                        placeholder='Type message here'
                        className="message-input"
                        onSend={submit}
                        onChange={(e, value) => setText(value)}
                        sendButton={true}
                        disabled={botIsTyping? true : false}
                        attachButton={false}
                    />
                </ChatContainer>
            </MainContainer>
        )}
    </div>
    );

}


export default Widget;